<template>
  <div class="access-permission-container">

    <vs-row class="mt-5">
      <vs-card class="fix-pads">
        <div slot="header">
          <div class="flex justify-between items-center">
            <h3 class="font-medium">Groups</h3>

          </div>
        </div>
       <div class=" flex justify-end mb-8">
          <vs-button
              class="only-border-btn "
              v-round
              size="medium"
              @click="$router.push({ name: 'merchant-group-create' })"
              >
              <plus-icon size="1.5x" class="button-icon"></plus-icon> New User Group</vs-button
            >
        </div>
        <vs-row class="grid group-grid">
          <vs-card
            class="groupCard p-5 mb-0"
            v-for="(group, indextr) in merchantGroups"
            :key="indextr"
          >
            <span class="element">
              <a @click="openEditGroup(group._id)"
                ><img :src=edit alt="edit"></a
            ></span>
            <vs-row>
              <h1 class="font-medium text-2xl">{{ group.groupName }}</h1>
            </vs-row>
            <vs-row class="mt-3">
              <p>
                <span>{{ group.users.length }}</span>
                {{ group.users.length > 1 ? "users" : "user" }} have access to:
              </p>
            </vs-row>
            <vs-row class="mt-6">
              <p class="font-normal text-base text-black">
                <span class="numberRT mr-2">{{
                  group.permissionsOnRequestTemplate
                    ? group.permissionsOnRequestTemplate.length
                    : "0"
                }}</span>
                request templates
              </p>
            </vs-row>
            <vs-row class="pl-6 mt-2">
              <p class="text-sm" v-for="(rt, i) in group.permissionsOnRequestTemplate" :key="i">
                {{ `${rt.templateName},&nbsp; ` }}
              </p>
            </vs-row>
            <vs-row class="mt-4">
              <p class="font-normal text-base text-black">
                <span class="numberRT mr-2">{{
                  group.permissionsOnHostedPages
                    ? group.permissionsOnHostedPages.length
                    : "0"
                }}</span>
                hosted payment pages
              </p>
            </vs-row>
            <vs-row class="pl-6 mt-2">
              <p class="text-sm" v-for="(hp, i) in group.permissionsOnHostedPages" :key="i">
                {{ `${hp.templateName},&nbsp; ` }}
              </p>
            </vs-row>
          </vs-card>
        </vs-row>
      </vs-card>
    </vs-row>
    <!-- <vs-row>
      <vs-card class="fix-pads">
        <div slot="header">
          <div class="flex justify-between items-center">
            <h3 class="font-semibold">API Groups</h3>
          </div>
        </div>
        <vs-row class="m-3">
          <vs-card
            class="groupCard p-5"
            v-for="(group, indextr) in apiMerchantGroups"
            :key="indextr"
          >
            <span class="element">
              <a @click="openAPIEditGroup(group._id)"
                ><edit-icon size="1.5x" class="custom-class"></edit-icon></a
            ></span>
            <vs-row>
              <h1 class="font-semibold text-2xl">{{ group.groupName }}</h1>
            </vs-row>
            <vs-row class="mt-3">
              <p>
                <span>{{ group.totalUsers }} users have access</span>
              </p>
            </vs-row>
          </vs-card>
        </vs-row>
      </vs-card>
    </vs-row> -->
  </div>
</template>
<script>
  import { mapActions } from "vuex";
  import Swal from "sweetalert2/dist/sweetalert2.js";
  import { EditIcon, PlusIcon } from "vue-feather-icons";

  import "sweetalert2/src/sweetalert2.scss";
  const edit=require("@/assets/images/edit.png")
  export default {
    components: {
      EditIcon,
      PlusIcon
    },
    data() {
      return {
        descriptionItems: [20, 25, 40],
        users: [],
        merchantGroups: [],
        apiMerchantGroups: [],
        edit:edit
      };
    },
    methods: {
      ...mapActions("merchantGroup", [
        "fetchMerchantGroupByMerchantId",
        "fetchMerchantAPIGroupByMerchantId",
      ]),
      ...mapActions("admin", ["checkLogin"]),

      // functions for merchantGroup

      async getMerchantGroups() {
        await this.fetchMerchantGroupByMerchantId(this.partnerId)
          .then((res) => {
            this.merchantGroups = res.data.data.docs;
          })
          .catch((err) =>{
            this.$vs.notify({
              title: "Products",
              text: err.response.data.message,
              iconPack: "feather",
              icon: "icon-times-circle",
              color: "danger",
              position: "top-right",
            });
            if(err.response.status === 400) {
              this.$router.push({name:'settings'});
            }
          });
      },
      async getMerchantAPIGroups() {
        await this.fetchMerchantAPIGroupByMerchantId(this.partnerId)
          .then((res) => {
            this.apiMerchantGroups =
              res.data.data.docs &&
              res.data.data.docs.map((item) => {
                const totalViewOnly = item.apiUsersPermissions
                  ? item.apiUsersPermissions.view
                    ? item.apiUsersPermissions.view.length
                    : 0
                  : 0;
                const totalEditOnly = item.apiUsersPermissions
                  ? item.apiUsersPermissions.edit
                    ? item.apiUsersPermissions.edit.length
                    : 0
                  : 0;
                const totalUsers = totalViewOnly + totalEditOnly;
                return { ...item, totalUsers };
              });
          })
          .catch((err) => {});
      },

      openEditGroup(id) {
        this.$router.push({
          name: "merchant-group-edit",
          params: { id: id },
        });
      },
      openAPIEditGroup(id) {
        this.$router.push({
          name: "merchant-api-group-edit",
          params: { id: id },
        });
      },
      async checkAdminLogin() {
        await this.checkLogin();
      },
    },

    mounted() {
      this.getMerchantGroups();
      this.getMerchantAPIGroups();
      this.checkAdminLogin();
    },

    computed: {
      user() {
        return this.$store.state.AppActiveUser;
      },
      partnerId() {
      return this.user.userType === "admin"
        ? this.user._id
        : this.user.partnerId
    },
    },
  };
  </script>